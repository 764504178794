import { Box, Grid, Typography, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import appContext from "../../context/AppContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Triangle } from "react-loader-spinner";
import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FeedBack from "./FeedBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Oval } from "react-loader-spinner";

const Review = () => {
  // AWS.config.update({
  //   accessKeyId: process.env.REACT_APP_ACCESS,
  //   secretAccessKey: process.env.REACT_APP_SECRET,
  //   region: process.env.REACT_APP_REGION,
  // });

  // const s3 = new AWS.S3();
  const client = new S3Client({
    region: process.env.REACT_APP_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_ACCESS,
      secretAccessKey: process.env.REACT_APP_SECRET,
    },
  });
  const navigate = useNavigate();
  const AppContext = useContext(appContext);
  const [videoSrc, setVideoSrc] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loader, setLoader] = useState(true);
  const [expanded, setExpanded] = React.useState(false);
  console.log("uploadProgress", AppContext?.uploadProgress);
  // const dummyObject = [
  //     {
  //         title: 'Please introduce yourself?',
  //         src: 'https://interview-recorded-videos.s3.ap-southeast-1.amazonaws.com/1671451366814.mp4'
  //     },
  //     {
  //         title: 'What is Your notice period?',
  //         src: 'https://interview-recorded-videos.s3.ap-southeast-1.amazonaws.com/1671451366814.mp4'
  //     }
  // ]

  //   useEffect(() => {
  //     // setVideo(AppContext?.uploadProgress[0]);
  //     // setVideo(dummyObject[0].src);
  //   }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  var setVideo = (url) => {
    console.log("vivz The URL is", url);
    setVideoSrc(url);
    // const myBucket = process.env.REACT_APP_BUCKET_NAME;
    // const myKey = url.split("amazonaws.com/")[1];
    // const signedUrlExpireSeconds = 60 * 10;
    // S3 getSignedUrl with callbacks are not supported in AWS SDK for JavaScript (v3).
    // Please convert to 'client.getSignedUrl(apiName, options)', and re-run aws-sdk-js-codemod.
    // const command = new GetObjectCommand({
    //   Bucket: myBucket,
    //   Key: myKey,
    //   Expires: signedUrlExpireSeconds,
    // });

    // s3.getSignedUrl(
    //   "getObject",
    //   {
    //     Bucket: myBucket,
    //     Key: myKey,
    //     Expires: signedUrlExpireSeconds,
    //   },
    //   function (err, URL) {
    //     console.log(err);
    //     console.log("The URL is", URL);
    //     setVideoSrc(URL);
    //   }
    // );
    // try {
    //   const response = client.send(command);
    //   console.log("response", response);
    //   // The Body object also has 'transformToByteArray' and 'transformToWebStream' methods.
    //   const str =  response.Body.transformToString();
    //   console.log("str", str);
    // } catch (err) {
    //   console.error(err);
    // }
    // client
    //   .send(command)
    //   .then((data) => {
    //     // 'data.Body' contains the video content
    //     // const videoContent = data.Body;

    //     // Do something with the video content (e.g., save it to a file)
    //     // fs.writeFileSync("path/to/save/video.mp4", videoContent);
    //     // console.log("Video downloaded successfully!");
    //     return data.Body;
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching video:", error);
    //   });
  };
  const loaderFunc = () => {
    setTimeout(() => {
      setLoader(false);
    }, 10000);

    return (
      <Triangle
        height="50"
        width="50"
        radius="7"
        color="green"
        ariaLabel="three-dots-loading"
        wrapperStyle
        wrapperClass
      />
    );
  };

  return (
    <>
      {console.log("vivz AppContext?.interview_data ", AppContext)}
      <Grid className="StickyContent">
        {/* <Card sx={{ padding: "15px 10px", boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px", borderRadius: "8px" }}> */}
        {/* <CardContent> */}
        {/* <Box sx={{ width: '100%' }}> */}
        <Box sx={{ textAlign: "center", margin: "30px 0px 30px 0px" }}>
          <CheckCircleIcon
            sx={{ color: "#299D51", margin: "10px 0px 0px 0px" }}
            fontSize="large"
          />
          <Typography sx={{ marginTop: "10px", color: "#299D51" }}>
            <b>Assessment Completed</b>
          </Typography>
          <Typography sx={{ marginTop: "10px" }}>
            You have successfully completed the assessment for
          </Typography>
          <Typography
            align="center"
            variant="h5"
            sx={{ fontWeight: 700, margin: "0px 0px 0px 0px" }}
          >
            {" "}
            {AppContext?.interview_data?.title}
          </Typography>
          {/* <Typography sx={{ margin: { sm: "20px 0px", xs: "20px 0px 10px 0px" }, color: "#23272A", fontSize: { sm: "20px", xs: "16px" } }}>{AppContext?.interview_data?.goodbye_note}</Typography> */}
          <Typography sx={{ fontSize: "16px", marginTop: "20px" }}>
            You can now review your answers below
          </Typography>
        </Box>
        {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid item xs={6}>
                                  <Box sx={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px", height: "300px", "overflow-y": "auto", "border-radius": "15px" }}>
                                      {console.log("AppContext?.interview_data?.question_id", AppContext?.interview_data?.interview_questions)}
                                      {AppContext?.interview_data?.interview_questions?.map((u, index) => (
                                          <Box style={{
                                              cursor: "pointer",
                                              color: index === selectedIndex ? "#fff" : "#000",
                                              background: index === selectedIndex ? "#39aed9" : "none"
                                          }} onClick={() => {
                                              setSelectedIndex(index)
                                              setVideo(AppContext?.uploadProgress[index])
                                          }} sx={{ borderBottom: "1px solid #e2e2e2", padding: "15px 10px" }}>
                                              <Typography >{index + 1} :  {u?.title}</Typography>
                                          </Box>
                                      ))}
                                  </Box>
                              </Grid>
                              <Grid item xs={6}>
                                  <video style={{ height: '300px', width: '540px', "border-radius": "15px" }} controls autoPlay src={videoSrc} ></video>
                              </Grid> */}
        {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}> */}
        <Grid item lg={12}>
          <Box
          // sx={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px", height: "300px", "overflow-y": "auto", "border-radius": "15px" }}
          >
            {AppContext.uploadProgress.length > 0 ? (
              AppContext?.interview_data?.interview_questions?.map(
                (u, index) => (
                  // <Box style={{
                  //     cursor: "pointer",
                  //     color: index === selectedIndex ? "#fff" : "#000",
                  //     background: index === selectedIndex ? "#39aed9" : "none"
                  // }}
                  // // onClick={() => {
                  // //     setSelectedIndex(index)
                  // //     setVideo(AppContext?.uploadProgress[index])
                  // // }}
                  // sx={{ borderBottom: "1px solid #e2e2e2", padding: "15px 10px" }}>
                  //     <Typography >{index + 1} :  {u?.title}</Typography>
                  // </Box>
                  <Accordion
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleChange(`panel${index + 1}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: "#39aed9" }} />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{
                        cursor: "pointer",
                        // color: index === selectedIndex ? "#fff" : "#000",
                        // background: index === selectedIndex ? "#39aed9" : "none",
                        border:
                          index === selectedIndex ? "1px solid #39aed9" : "",
                      }}
                      onClick={() => {
                        setSelectedIndex(index);
                        setVideo(AppContext?.uploadProgress[index]);
                      }}
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        {`Question #${index + 1}`}
                      </Typography>
                      {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* <Typography>
                                                      Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
                                                      Aliquam eget maximus est, id dignissim quam.
                                                    </Typography> */}
                      <Box
                        //    style={{
                        //         cursor: "pointer",
                        //         color: index === selectedIndex ? "#fff" : "#000",
                        //         background: index === selectedIndex ? "#39aed9" : "none"
                        //     }}
                        style={{
                          height: "auto",
                        }}
                        // onClick={() => {
                        //     setSelectedIndex(index)
                        //     setVideo(AppContext?.uploadProgress[index])
                        // }}
                        sx={{ padding: "15px 10px" }}
                      >
                        <Grid
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                          <Grid item xs={6}>
                            {u.question_type === "audio/video" ? (
                              <video
                                style={{
                                  height: "200px",
                                  width: "100%",
                                  "border-radius": "15px",
                                  marginTop: "-10px",
                                  objectFit: "contain",
                                }}
                                controls
                                src={videoSrc}
                              ></video>
                            ) : (
                              <div
                                dangerouslySetInnerHTML={{ __html: videoSrc }}
                                style={{
                                  whiteSpace: "pre-line",
                                }}
                              ></div>
                            )}
                          </Grid>
                          <Grid xs={6} sx={{ padding: "20px 15px" }}>
                            {/* <Typography>{u?.title}</Typography> */}
                            {/* <div
                              dangerouslySetInnerHTML={{ __html: u?.title }}
                            ></div> */}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: AppContext?.isDarkMode
                                  ? u?.title.replaceAll(
                                      "color: rgb(0, 0, 0);",
                                      "color: #fff;"
                                    )
                                  : u?.title,
                              }}
                            ></div>
                          </Grid>
                        </Grid>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                )
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ margin: "20px 0px 30px 50px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      //   padding: "10px",
                    }}
                  >
                    <Oval
                      height={80}
                      width={80}
                      color="#1976d2"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#1976d2"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </div>
                  <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                    Loading Questions, Please wait
                  </Typography>
                </div>
              </div>
            )}
          </Box>
        </Grid>
        {/* <Grid item xs={6}>
                                      <video style={{ height: '300px', width: '540px', "border-radius": "15px" }} controls autoPlay src={videoSrc} ></video>
                                  </Grid> */}

        {/* </Grid> */}
        <Typography
          align="center"
          sx={{ margin: { sm: "20px 0px", xs: "20px 0px 10px 0px" } }}
        >
          {AppContext?.interview_data?.goodbye_note}
        </Typography>
        {/* </Box> */}
        {/* </CardContent> */}
        {/* <div> */}
        <FeedBack />
        {/* </div> */}
        {/* </Card> */}
      </Grid>
    </>
  );
};

export default Review;
