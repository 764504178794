const api = "https://api.airec.io";

// const api = "http://e2e-api.test";
export const environment = {
  API_URL: `${api}`,
  getApplicant: `${api}/interview-detail`,
  // updateQuestion: `${api}/applicant/update/question`,
  updateApplicant: `${api}/interview/submit-answer`,
  updateCount: `${api}/interview/count`,
  feedBack: `${api}/interview/feedback`,
};

// const api = "https://backend-project.test";
// const api = "http://localhost:9000";
//const api = "https://videoapp2.sanswebsolutions.in";
// const api = "https://api.dev.airec.io";
