import { Box, Grid, Typography, Button } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import QandA from "./QandA";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import appContext from "../../context/AppContext";
import { Oval } from "react-loader-spinner";
import Feedback from "../../images/feedback.svg";

import PracticeQandA from "./PracticeQandA";
import { useLocation } from "react-router-dom";

window.Buffer = window.Buffer || require("buffer").Buffer;

const options = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS,
  secretAccessKey: process.env.REACT_APP_SECRET,
};

const QuestionAnswer = () => {
  const location = useLocation();
  const isSurvey = location?.state?.isSurvey;

  const [questionNumber, SetQuestionNumber] = useState(0);
  const [open, setOpen] = useState(false);
  const [screenShare, setScreenShare] = useState(true);
  // const [isSurvey, setIsSurvey] = useState(true);
  const AppContext = useContext(appContext);
  const [loader, setLoader] = useState(true);
  const callLoader = () => {
    enterFullScreen();
    setLoader(false);
    setTimeout(() => {
      setLoader(true);
    }, 2000);
  };
  const surveyArr = [
    {
      title: "When faced with a new challenge at work, how do you approach it?",
      reading_time: "8",
      time_duration: "35",
      order_no: 1,
      screen_share: "false",
      question_type: "mcq",
      answers: [
        "I brainstorm multiple solutions before acting.",
        "I dive in and figure things out as I go.",
        "I research the best practices and adopt proven methods.",
      ],
    },
    {
      title: "How do you prefer to learn new skills?",
      reading_time: "8",
      time_duration: "35",
      order_no: 2,
      screen_share: "false",
      question_type: "mcq",
      answers: [
        "I explore creative ways to master the skill.",
        "I rely on structured learning materials.",
        "I focus on gaining practical, hands-on experience",
      ],
    },
    {
      title:
        "Describe a time when you had to think outside the box to solve a problem.",
      reading_time: "8",
      time_duration: "35",
      order_no: 3,
      screen_share: "false",
      question_type: "audio/video",
    },
  ];
  const queArr = [
    {
      title:
        "Please introduce your self briefly.Highlight your career achievement and what makes you the most suitable candidate for this role?",
      reading_time: "8",
      time_duration: "25",
      order_no: 1,
      screen_share: "false",
      question_type: "audio/video",
    },
    {
      title:
        "How would you manage data consistency across multiple microservices that interact with the same data source?",
      reading_time: "8",
      time_duration: "30",
      order_no: 2,
      screen_share: "false",
      question_type: "mcq",
      answers: [
        "By using a distributed database for direct writes from each service",
        "By implementing eventual consistency and using a messaging system like Kafka for updates.",
        "By using two-phase commit (2PC) for every transaction",
        "By writing all data to a single central database to ensure strong consistency.",
      ],
    },
    {
      title: `<p style={{ lineHeight: '0.5' }}>
  You are tasked with fetching a list of users from the database along with the count of their active orders (orders with a status of 'active') and their latest order details.
  Write an Eloquent query that retrieves this data efficiently.<br />
  Avoid N+1 query problems.<br />
  <strong>Expected Output:</strong><br />
  - User ID<br />
  - User Name<br />
  - Count of Active Orders<br />
  - Latest Order Details (Order ID, Order Date, Order Status)<br />
</p>
 `,
      reading_time: "12",
      time_duration: "40",
      order_no: 3,
      screen_share: "false",
      question_type: "code",
    },
  ];
  const enterFullScreen = () => {
    let document = window.document;
    document.body.classList.add("fullscreen-enable");
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen();
    }
  };

  useEffect(() => {
    if (!screenShare) {
      enterFullScreen();
    }
    setScreenShare(
      isSurvey
        ? surveyArr[questionNumber]?.screen_share === "true"
          ? false
          : true
        : queArr[questionNumber]?.screen_share === "true"
        ? false
        : true
    );
  }, [
    isSurvey ? surveyArr[questionNumber] : queArr[questionNumber]?.screen_share,
  ]);

  const saveAnswer = () => {
    SetQuestionNumber(questionNumber + 1);
  };

  const exit = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid className="StickyContentQA">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginBottom: "0px",
          position: "fixed",
          padding: "0px 18px 0px 10px",
          top: 20,
          left: 25,
          width: "100%",
          zIndex: 100,
        }}
      >
        <Typography
          sx={{ fontSize: "18px", fontWeight: "600", marginTop: "2px" }}
        >
          Welcome to practise Interview
        </Typography>

        <Button
          variant="contained"
          className="primaryBtn"
          onClick={exit}
          style={{
            background: "#cc0000 !important",
            position: "absolute",
            right: "50px",
          }}
        >
          Exit Interview Process
        </Button>

        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {" Are you sure want to Exit Interview?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button
              onClick={handleClose}
              href={`/${AppContext.token}`}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <div style={{ maxWidth: "1200px", marginTop: "8px" }}>
        {/* <Card sx={{ padding: "15px 10px", boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px", borderRadius: "8px" }}> */}
        {screenShare ? (
          <div>
            {loader ? (
              <CardContent
                style={{
                  height: "40vh",
                }}
              >
                {
                  !isSurvey
                    ? queArr.length > 0 && (
                        <PracticeQandA
                          saveAnswer={saveAnswer}
                          question={queArr[questionNumber]}
                          question_id={queArr.order_no}
                          questionNumber={questionNumber}
                          totalQuestionCount={queArr.length}
                          isLastQuestion={questionNumber >= queArr.length - 1}
                          callLoader={callLoader}
                        />
                      )
                    : surveyArr.length > 0 && (
                        <PracticeQandA
                          saveAnswer={saveAnswer}
                          question={surveyArr[questionNumber]}
                          question_id={surveyArr.order_no}
                          questionNumber={questionNumber}
                          totalQuestionCount={surveyArr.length}
                          isLastQuestion={
                            questionNumber >= surveyArr.length - 1
                          }
                          callLoader={callLoader}
                        />
                      )
                  // <QandA
                  //   saveAnswer={saveAnswer}
                  //   question={queArr[questionNumber]}
                  //   questionNumber={questionNumber}
                  //   isLastQuestion={questionNumber >= queArr.length - 1}
                  //   totalQuestionCount={queArr.length}
                  //   callLoader={callLoader}
                  // />
                }
              </CardContent>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{}}>
                  <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                    Your answer is being submitted, please wait
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    <Oval
                      height={80}
                      width={80}
                      color="#1976d2"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#1976d2"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <Box>
            <CardContent
              sx={{ padding: "30px", margin: "20px", "text-align": "center" }}
            >
              <Typography
                sx={{ color: "#23272A", fontSize: "18px", fontWeight: "600" }}
              >
                Next Question need your screen share permission,<br></br> please
                allow the permission at the time of answer.
              </Typography>
              <Box sx={{ pt: 5 }}>
                <Button
                  variant="contained"
                  className="primaryBtn"
                  onClick={() => {
                    setScreenShare(true);
                  }}
                >
                  Ok
                </Button>
              </Box>
            </CardContent>
          </Box>
        )}
        {/* </Card> */}
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          "line-height": "25px",
          "margin-top": "270px",
          position: "fixed",
          bottom: 75,
          left: 0,
          padding: "0px 20px 0px 10px",
          width: "100%",
          zIndex: 100,
          backgroundColor: AppContext?.isDarkMode ? "#282c34" : "white",
        }}
      >
        <div style={{ justifyContent: "left" }}>
          <img src={Feedback} style={{ width: "20px", height: "12px" }} />
          <span className="lowerTextLeft">
            Feedback or suggestions: hello@airec.io
          </span>
        </div>
        <div style={{ justifyContent: "right" }}>
          <img src={Feedback} style={{ width: "20px", height: "12px" }} />
          <span className="lowerTextLeft" style={{}}>
            {/* Note: Interviews audio and videos will be saved within our servers
            for a period of six months only */}
            Note: Interview data will be saved for a period of six month
          </span>
        </div>
      </Box>
    </Grid>
  );
};

export default QuestionAnswer;
